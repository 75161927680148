import React from 'react';
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import * as bulmaToast from "bulma-toast";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    let showContact = false;
    if(window.location.href.includes('showContact=true')) {
      showContact = true;
    }
    this.state = { showContact, contactEmail: '', contactMessage: '' };
  }

  handleSubmit(e) {
    e.preventDefault();

    fetch("https://submit-form.com/9qkZEoonHjR1rbW8g7TsZ", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({
          message: this.state.contactMessage,
          email: this.state.contactEmail,
        })
      })
    .then(response => {
        if(response.status === 200) {
          this.setState({ showContact: false, contactMessage: '' });
          bulmaToast.toast({ message: 'Message sent!',
            type: 'is-light',
            position: 'bottom-left',
            dismissable: true,
            duration: 4000,
            animate: { in: "bounceInUp", out: "bounceOutDown" } })
        }
    })
    .catch(function(error) {
      console.error(error);
    });
  }


  render() {
    return <>

    <footer>
      <center>Copyright ©️ 2020. Made with ❤️ by <a target="_blank" href="https://tonygarvan.dev">Anthony Garvan</a>.</center>
      <center><a  target="_blank" href="/privacy.txt">Privacy</a> | <a  target="_blank" href="/terms.txt">Terms</a> | <a onClick={() => this.setState({ showContact: true })}>Contact</a></center>
    </footer>

  <div className={`re-contact modal ${this.state.showContact && 'is-active'}`}>
      <div className="modal-background" onClick={() => this.setState({ showContact: false })}></div>

      <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">Thanks for reaching out!</p>
        <button className="delete" aria-label="close" onClick={() => this.setState({showContact: false})}></button>
      </header>
      <section className="modal-card-body content">
        <p>I welcome bug reports, feature requests, comments, questions, concerns, manifestos, proposals,
          really anything that's on your mind. You can also use this form to request to delete, access, or transfer your data. I&apos;ll do my best to get back to you within two business days.</p>
        <form target="_self" onSubmit={this.handleSubmit}>
          <div className="field">
           <label className="label">Email</label>
           <div className="control has-icons-left has-icons-right">
             <input className="input" name="email" type="email" placeholder="your.email@example.com" value={this.state.contactEmail}
               onChange={(e) => this.setState({ contactEmail: e.target.value})} required />
             <span className="icon is-small is-left">
               <FontAwesomeIcon icon={faEnvelope} />
             </span>
           </div>
          </div>

          <div className="field">
           <label className="label">Message</label>
           <div className="control">
             <textarea className="textarea" name="message" placeholder="Your message.."
               value={this.state.contactMessage} onChange={(e) => this.setState({ contactMessage: e.target.value})} required></textarea>
           </div>
          </div>


          <div className="field is-grouped">
            <div className="control">
              <a className="button is-text" onClick={() => this.setState({ showContact: false })}>Cancel</a>
            </div>
           <div className="control">
             <button className="button is-primary" type="submit">Submit</button>
           </div>
          </div>

        </form>

      </section>
      </div>
    </div>
  </>
  }
}

export default Footer;
