import React from 'react';
import Header from './Header';
import screen1 from './img/screen1.png';
import screen2 from './img/screen2.png';
import screen3 from './img/screen3.png';
import slackLogo from './img/slackLogo.png';
import './Splash.scss';
import Arrow from './Arrow';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from 'react-responsive-carousel';

class Splash extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    return <>
    <Header />

    <div className="re-hero columns">
      <div className="column">
        <h1 className="title is-1">Stop nagging your team. You hate it. They hate it. Now there's a better way.</h1>
        <div className="buttons"><a href="/docs.html?page=overview;" className="button is-text">View docs</a><a href="/auth/add" className="button"><img src={slackLogo}
          alt="slack logo"/>Add to &nbsp;<b>Slack</b></a></div>
        <p>RemindUs is an advanced reminder app built for slack teams. Whether it be for timecards, status reports, project deadlines,
          or really anything else with a due date, now you can set it up once and have the bot tackle the busy work.
          You didn't get this far in life to waste time pestering your team. </p>
      </div>
      <div className="column">
        <Carousel showThumbs={false} showStatus={false}
          autoPlay={true} showArrows={false}
          interval={7000} infiniteLoop={true}>
          <img className="re-screenshot" src={screen1} alt="screenshot one"/>
          <img className="re-screenshot" src={screen2} alt="screenshot two"/>
          <img className="re-screenshot" src={screen3} alt="screenshot three"/>
        </Carousel>
      </div>
    </div>

    <div className="re-arrow-container-1">
      <Arrow />
    </div>

    <div className="re-features">
      <h1 className="title is-1">All the bells and whistles</h1>

      <p><b>🔔 Follows up for you.</b>&nbsp;&nbsp;If someone hasn't finished the task by the deadline,
        you can set up RemindUs to send them a direct message to let them know to follow up.
        No more tracking down people or distracting <code>@channels</code>.
        It also supports "pre-reminders" which let the team know the deadline is coming up.</p>

      <p><b>🗒️ Keeps you organized.</b>&nbsp;&nbsp;Easily see who's completed each task, who hasn't done it yet, and who hasn't responded yet.
        You can also see and respond to any reminders assigned to you.
        And RemindUs supports advanced logic for recurring reminders that will fit into your project cadences,
        such as "every other month on the 15, avoiding weekends, repeating 3 times."</p>

      <p><b>🤍 Your team will love it.</b>&nbsp;&nbsp;Built from the ground up to reduce stress for all involved,
        from eliminating the need for intense DMs to built in etiquette like honoring local time zones and weekends.
        RemindUs will free you up from annoying administrative exchanges so that you can support your team in meaningful ways.
      </p>
  </div>

    <div className="re-arrow-container-2">
      <Arrow />
    </div>

    <div className="re-easy">
      <h1 className="title is-1">Easy to use</h1>

      <div>Wouldn't it be a bummer if you couldn't remember how to use your reminder app? With RemindUs there's no complex syntax, just
      three simple commands:</div>

      <table className="re-commands">
      <tbody>
        <tr>
          <td><code>/remindus create</code></td>
          <td>Creates a new reminder.</td>
        </tr>
        <tr>
          <td><code>/remindus list</code></td>
          <td>View and modify your reminders.</td>
        </tr>
        <tr>
          <td><code>/remindus tasks</code></td>
          <td>Respond to tasks assigned to you.</td>
        </tr>
      </tbody>
      </table>
    </div>

    <div className="re-arrow-container-3">
      <Arrow />
    </div>

    <div className="re-focus">
      <h2 className="title is-2">Focus on what matters</h2>

      <ul>
        <li><FontAwesomeIcon icon={faArrowRight} />&nbsp;&nbsp;Don't waste cycles tracking down who's done what when</li>
        <li><FontAwesomeIcon icon={faArrowRight} />&nbsp;&nbsp;Organize and improve your compliance for team tasks</li>
        <li><FontAwesomeIcon icon={faArrowRight} />&nbsp;&nbsp;Don't let important things fall through the cracks</li>
      </ul>

    <blockquote>You and your team are busy. Get a little help.</blockquote>

    </div>

    <Footer />
    </>
  }
}

export default Splash;
