import React from 'react';
import Logo from './img/logo.png';
import './Header.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { menuActive: false };
  }

  render() {
  return <><nav className="navbar" role="navigation" aria-label="main navigation">
  <div className="navbar-brand">
    <a className="navbar-item" href="/index.html">
      <img src={Logo} />&nbsp;&nbsp;&nbsp;
      <h3 className="title is-3">RemindUs</h3>
    </a>

    <a role="button" className={`navbar-burger burger ${this.state.menuActive && 'is-active'}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"
        onClick={() => this.setState({ menuActive: !this.state.menuActive })}>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div className={`navbar-menu ${this.state.menuActive && 'is-active'}`}>
    <div className="navbar-start">
    </div>

    <div className="navbar-end">
      <div className="navbar-item">
        <button onClick={() => this.setState({ showPricing: true })} className="button is-text">Pricing</button>
      </div>
      <div className="navbar-item has-dropdown is-hoverable">
        <a className="navbar-link button is-text" href="/docs.html?page=overview;">
      Docs</a>

    <div className="navbar-dropdown">
      <a className="navbar-item" href="/docs.html?page=overview;">
        Overview
      </a>
      <hr className="navbar-divider" />
      <a className="navbar-item" href="/docs.html?page=create;">
        Creating reminders
      </a>
      <a className="navbar-item" href="/docs.html?page=list;">
        Listing reminders
      </a>
      <a className="navbar-item" href="/docs.html?page=tasks;">
        Viewing tasks
      </a>
      <a className="navbar-item" href="/docs.html?page=data;">
        Data & security
      </a>
    </div>
      </div>
      <div className="navbar-item">
        <div className="buttons">
          {this.props.dashboard ? <a className="button" href="/auth/logout">
            <strong>Sign out</strong>
          </a>
          : <a className="button" href="/auth/try-login">
            <strong>Sign in</strong>
          </a>}
        </div>
      </div>
    </div>
  </div>
</nav>

<div className={`re-pricing modal ${this.state.showPricing && 'is-active'}`}>
<div className="modal-background" onClick={() => this.setState({ showPricing: false })}></div>
<div className="modal-card">
  <header className="modal-card-head">
    <p className="modal-card-title">Pricing</p>
    <button className="delete" aria-label="close" onClick={() => this.setState({ showPricing: false })}></button>
  </header>
  <section className="modal-card-body content">
    <p>Pricing is based on the number of seats for <em>active</em> users. An active user is any user with any (undeleted) reminders. For example, if five team members wanted to use
    RemindUs, you would need two additional seats and pay $4 / month.</p>
    <div className="columns">
      <div className="column">
        <h3 className="title is-3">Free</h3>
        <h5 className="subtitle is-5">1-3 active users</h5>
      </div>
      <div className="column">
        <h3 className="title is-3">$2 <em>/ user / month</em></h3>
        <h5 className="subtitle is-5">Each additional user</h5>
      </div>
    </div>
  </section>
</div>
</div>
</>
}
}

export default Header;
